import React, { useState } from "react";
import classnames from "classnames";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <nav
        className="flex justify-between items-center bg-paper py-4 px-6"
        role="navigation"
      >
        <Link to="/" className="xs:text-3xl md:text-3.5xl lg:text-4xl">
          <span className="text-tropicalForestGreen font-bold">Geo</span>
          <span className="text-skyscraper">LC</span>
        </Link>

        <ul
          className={classnames(
            "xs:fixed xs:top-16 xs:bg-white xs:flex-col xs:bottom-0 xs:h-auto xs:text-center xs:z-10 xs:transition-all xs:duration-500 xs:w-full",
            "lg:flex lg:justify-between lg:bg-paper lg:items-center lg:flex-row lg:top-auto lg:z-0 lg:w-auto lg:bottom-auto lg:left-auto lg:relative",
            "m-0",
            {
              "xs:-left-full": !isOpen,
              "xs:left-0": isOpen,
            }
          )}
        >
          <li className="xs:my-10 lg:my-0">
            <AnchorLink
              to="/#about"
              onAnchorLinkClick={() => setIsOpen(false)}
              className="text-black py-0 px-3 font-medium text-lg"
            >
              O spoločnosti
            </AnchorLink>
          </li>
          <li className="xs:my-10 lg:my-0">
            <AnchorLink
              to="/#services"
              onAnchorLinkClick={() => setIsOpen(false)}
              className="text-black py-0 px-3 font-medium text-lg"
            >
              Služby
            </AnchorLink>
          </li>
          <li className="xs:my-10 lg:my-0">
            <AnchorLink
              to="/#contact"
              onAnchorLinkClick={() => setIsOpen(false)}
              className="bg-tropicalForestGreenDarker text-white py-1 px-3 font-medium text-lg"
            >
              Kontakt
            </AnchorLink>
          </li>
        </ul>

        <button
          aria-label="hamburger menu"
          className="xs:block lg:hidden cursor-pointer focus:outline-off"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={classnames(
              "block w-6 h-3px my-5px mx-auto bg-tropicalForestGreen",
              "transition-all duration-300 ease-in-out",
              {
                "transform-gpu translate-y-2 rotate-45": isOpen,
              }
            )}
          />
          <span
            className={classnames(
              "block w-6 h-3px my-5px mx-auto bg-tropicalForestGreen",
              "transition-all duration-300 ease-in-out",
              {
                "opacity-0": isOpen,
              }
            )}
          />
          <span
            className={classnames(
              "block w-6 h-3px my-5px mx-auto bg-tropicalForestGreen",
              "transition-all duration-300 ease-in-out",
              {
                "transform-gpu -translate-y-2 -rotate-45": isOpen,
              }
            )}
          />
        </button>
      </nav>
    </header>
  );
};

export default Header;
