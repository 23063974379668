import React from "react";

const SocialMediaIcon = ({ children, link }) => {
  return (
    <div className="lg:h-screen-3 lg:w-screen-3 xs:h-screen-6 xs:w-screen-6">
      <a href={link} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    </div>
  );
};

export default SocialMediaIcon;
