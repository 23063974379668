import React from "react";

import SocialMediaIcon from "../common/SocialMediaIcon";
import TilesWrapper from "../common/TilesWrapper";

import facebookIcon from "./facebook.png";
import gmailIcon from "./gmail.png";
import instagramIcon from "./instagram.png";

const Footer = () => {
  return (
    <div className="relative">
      <div className="flex flex-row w-screen/6 justify-around absolute xs:w-screen/3 lg:w-screen/6 xs:bottom-1/4 lg:bottom-55% xs:left-1/3 lg:left-screen/6">
        <SocialMediaIcon link="mailto:geodezialucenec@gmail.com">
          <img
            src={gmailIcon}
            alt="Gmail icon"
            title="Gmail"
            width={64}
            height={64}
          />
        </SocialMediaIcon>
        <SocialMediaIcon link="https://www.facebook.com/Geod%C3%A9zia-Lu%C4%8Denec-100269738356986">
          <img
            src={facebookIcon}
            alt="Facebook icon"
            title="Facebook"
            width={64}
            height={64}
          />
        </SocialMediaIcon>
        <SocialMediaIcon link="https://www.instagram.com/geodezia_lucenec/">
          <img
            src={instagramIcon}
            alt="Instagram icon"
            title="Instagram"
            width={64}
            height={64}
          />
        </SocialMediaIcon>
      </div>
      <span className="absolute left-1/2 leading-3 text-skyscrapper bottom-1/10 text-xs xs:text-0.4 md:text-0.5 lg:text-0.6 xl:text-0.7">
        <span className="relative -left-1/2">
          © 2020 GeoLC. Všetky práva vyhradené.
        </span>
      </span>
      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
      </TilesWrapper>
      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
      </TilesWrapper>
      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>
      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>
    </div>
  );
};

export default Footer;
